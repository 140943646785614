export const API_PREFIX = '';
export const API = `${API_PREFIX}restful/v1`;
export const CORE_API = `${API_PREFIX}/core/restful/v1`;
export const AJAX_API = `${API_PREFIX}ajax`;

export const APPLICATIONS_ENDPOINT = 'applications';

export const APPLICATION_GROUPS_ENDPOINT = 'applicationgroups';

export const CURRENT_USER_ENDPOINT = 'currentuser';
export const CURRENT_USER_ENDPOINT_ORGANISATION = `${CURRENT_USER_ENDPOINT}/organisation`;
export const CURRENT_USER_HOMEPAGE = `${CURRENT_USER_ENDPOINT}/homepage`;

export const DASHBOARD_ID_QUERY_PARAM = 'dashboardId';
export const DASHBOARDS_ENDPOINT = 'dashboards';
export const DASHBOARDS_ENDPOINT_FILTER = 'filter';
export const DASHBOARDS_ENDPOINT_SCHEDULE = 'schedule';
export const DASHBOARDS_ENDPOINT_FILTER_STATUS = `${DASHBOARDS_ENDPOINT_FILTER}/status`;
export const DASHBOARDS_ENDPOINT_FILTER_FIELDS = `${DASHBOARDS_ENDPOINT_FILTER}/fields`;
export const getDashboardsDownloadPdfEndpoint = (id: string, token: string) => `${DASHBOARDS_ENDPOINT}/${id}/data/pdf?csrf=${token}`;

export const ERRORS_ENDPOINT = 'error';

export const HEADER_ENDPOINT = 'header';

export const ICONS_ENDPOINT = 'icons';

export const INTEGRATION_PANELS_ENDPOINT = 'integration-panels/methods';
export const INTEGRATION_SETTINGS_ENDPOINT = `${API}/integration-settings`;

export const LOCATION_ENDPOINT = 'server/location';
export const LOCATIONS_ENDPOINT = 'servers/locations';

export const MENUS_ENDPOINT = 'menus';
export const MENUS_ENDPOINT_DASHBOARDS = `${MENUS_ENDPOINT}/dashboards`;
export const MENUS_ENDPOINT_APPLICATIONS = `${MENUS_ENDPOINT}/applications`;
export const MENUS_ENDPOINT_CYBER_APPLICATIONS = `${MENUS_ENDPOINT}/cyberApplications`;
export const MENUS_ENDPOINT_LOCATION = 'HOME_WITHOUT_HATEOAS_LINKS';

export const ORGS_ENDPOINT = 'organisations';
export const ORGS_ENDPOINT_LOGO = 'logo';
export const ORGS_ENDPOINT_HAS_BRANDING = 'hasBranding';
export const ORGS_CURRENT_ENDPOINT = `${ORGS_ENDPOINT}/current`;
export const getOrgLogoUrl = (subdomain: string): string => `${API}/${ORGS_ENDPOINT}/${subdomain}/${ORGS_ENDPOINT_LOGO}`;
export const getOrgHasBrandingUrl = (subdomain: string): string => `${API}/${ORGS_ENDPOINT}/${subdomain}/${ORGS_ENDPOINT_HAS_BRANDING}`;

export const PORTLETS_ENDPOINT = 'portlets';
export const PORTLETS_ENDPOINT_DATA = `data`;
export const downloadPortletEndpoint = (id: number, token: string, dashboardId: string) => `${PORTLETS_ENDPOINT}/${id}/data/pdf?csrf=${token}&${DASHBOARD_ID_QUERY_PARAM}=${dashboardId}`;

export const SHORTCUTS_ENDPOINT = 'shortcuts';

export const AUTH_ENDPOINT = `${API}/authentication`;
export const INVALIDATE_ENDPOINT = `${AUTH_ENDPOINT}/invalidate`;

export const TASKS_ENDPOINT = 'tasks';
export const TASKS_ENDPOINT_SUMMARY = `${TASKS_ENDPOINT}/summary`;

export const TILES_ENDPOINT = 'tiles';
export const TILES_ENDPOINT_COLOURS = `${TILES_ENDPOINT}/colours`;
export const TILES_ENDPOINT_DEFAULT = `${TILES_ENDPOINT}/default`;
export const TILES_ENDPOINT_UNDEFAULT = `${TILES_ENDPOINT}/undefault`;
export const TILES_ENDPOINT_SET_HOMEPAGE = `${TILES_ENDPOINT}/homepage`;
export const TILES_ENDPOINT_FAVOURITED = `${TILES_ENDPOINT}/favourited`;
export const TILES_ENDPOINT_LAST_ACCESS = `${TILES_ENDPOINT}/last-access`;
export const TILES_ENDPOINT_POSITION = `${TILES_ENDPOINT}/position`;
export const TILE_DASHBOARDS_ENDPOINT_DEFAULT_SUBSCRIPTION = `${TILES_ENDPOINT}/dashboardDefaultSubscription`;

export const USER_ENDPOINT = 'user';
export const USER_ENDPOINT_AUTH_PROFILE = `authenticationProfile`;
export const USER_ENDPOINT_IDP_AUTH_PROFILE = `authenticationProfileIDP`;
export const USER_ENDPOINT_RESET_DETAILS = `${USER_ENDPOINT}/resetDetails`;
export const USER_ENDPOINT_RESET_FROM_TOKEN = `${USER_ENDPOINT}/resetPasswordFromToken`;
export const USER_ENDPOINT_RESET_PASSWORD = `${USER_ENDPOINT}/resetPassword`;
export const USER_ENDPOINT_REVEAL_DETAIL = `${USER_ENDPOINT}/reveal`;
export const USER_ENDPOINT_VERIFY_EMAIL_ADDRESS = `${USER_ENDPOINT}/verifyEmailAddress`;

export const TEMPLATE_MIGRATIONS_ENDPOINT = `${API}/template-migrations`;
export const templateMigrationsOne = (key: string) => `${TEMPLATE_MIGRATIONS_ENDPOINT}/${key}`;
export const templateMigrationsAnalysisProgress = (key: string) => `${TEMPLATE_MIGRATIONS_ENDPOINT}/${key}/analysis-progress`;
export const templateMigrationsAnalysisResults = (key: string) => `${TEMPLATE_MIGRATIONS_ENDPOINT}/${key}/analysis`;
export const templateMigrationsAnalysisExcel = (key: string) => `${TEMPLATE_MIGRATIONS_ENDPOINT}/${key}/analysis-xls`;

export const PRINT_ENDPOINT = 'print';
export const PRINT_QUESTIONNAIRE_ENDPOINT = `${PRINT_ENDPOINT}/questionnaire`;

export const COOKIES_LINK = 'https://www.surecloud.com/surecloud-platform-cookies';
export const PRIVACY_LINK = 'https://www.surecloud.com/privacy-statement';
export const ONE_CLICK_ACTION = '/oneClick/action';

export const NOTICE_MANAGEMENT_ENDPOINT = `${API}/notices`;

